import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './Contacto.css'; 

const Contacto = () => {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [mensaje, setMensaje] = useState("");

  const enviarCorreo = (event) => {
    event.preventDefault();
    if (nombre === "" || email === "" || mensaje === "") {
      alert("Por favor, complete todos los campos del formulario.");
      return;
    }

    const mailtoLink = `mailto:pellegrinicarlosguillermo@gmail.com?subject=Consulta%20Via%20Web&body=Nombre: ${encodeURIComponent(nombre)}%0D%0AEmail: ${encodeURIComponent(email)}%0D%0AMensaje: ${encodeURIComponent(mensaje)}`;
    
    window.location.href = mailtoLink;
  };

  const whatsappURL = `https://api.whatsapp.com/send?phone=+5491170545655&text=Hola%20,me%20contacto%20por%20whatsapp%20por un presupuesto generado en la web%20`;

  return (
    <section className="contacto" id='contacto'>
      <div className="contacto-box">
        <p className='tituloform'>Contacto</p>
        <form>
          <div className="user-box">
            <input
              required
              type="text"
              id="nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
            <label htmlFor="nombre">Nombre</label>
          </div>
          <div className="user-box">
            <input
              required
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="email">Email</label>
          </div>
          <div className="user-box">
            <textarea
              required
              id="mensaje"
              value={mensaje}
              onChange={(e) => setMensaje(e.target.value)}
            ></textarea>
            <label htmlFor="mensaje">Mensaje</label>
          </div>
          <button onClick={enviarCorreo}>Enviar</button>
        </form>
      </div>
      <footer className="contacto-footer">
        <div className="footer-container">
          <div className="social-links">
          <a href={whatsappURL}><img src="https://img.icons8.com/office/16/whatsapp--v1.png" alt="whatsapp--v1" /></a>
            <a href="https://www.linkedin.com/in/pellegrinicg/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin}/>
            </a>
            <a href="mailto:pellegrinicarlosguillermo@gmail.com">
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </div>
          <div className="contact-info">
            <p><FontAwesomeIcon icon={faMapMarkerAlt} /> Dirección: Palermo, Buenos Aires</p>
            <p> 2024 by Nicolás Durso<a href="https://www.nicodurso.com"><FontAwesomeIcon icon={faLinkedin}/> nicodurso.com </a></p>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Contacto;
