import React, { useState } from 'react';
import './Navbar.css';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleToggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleCloseMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-brand">
          <img src="logoweb.png" width={50} alt="" />
          <h1 className='titulonav'>ESTUDIO PELLEGRINI</h1>
        </div>

        <div className={`mobile-menu-icon ${isMobileMenuOpen ? 'open' : ''}`} onClick={handleToggleMobileMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>

        <ul className={`nav-links ${isMobileMenuOpen ? 'open' : ''}`}>
          <li><a href='#inicio' onClick={handleCloseMobileMenu}>Inicio</a></li>
          <li><a href='#misión' onClick={handleCloseMobileMenu}>Misión</a></li>
          <li><a href='#servicios' onClick={handleCloseMobileMenu}>Servicios</a></li>
          <li><a href='#nosotros' onClick={handleCloseMobileMenu}>Nosotros</a></li>
          <li><a href='#calculador' onClick={handleCloseMobileMenu}>Calculador</a></li>
          <li><a href='#contacto' onClick={handleCloseMobileMenu}><img src= "https://img.icons8.com/color/48/message-squared.png" width={30} alt="contac-v1" color='white' /></a></li>
        </ul>

      </nav>
    </>
  );
};

export default Navbar;
