import React, {useEffect,useRef} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Page5 = () => {
    const serviciosRef = useRef(null);


    useEffect(() => {
        const serviciosElement = serviciosRef.current;
    
        const handleIntersection = (entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              // El elemento está en la vista
              serviciosElement.classList.add('aparecer');
            } else {
              // El elemento ya no está en la vista
              serviciosElement.classList.remove('aparecer');
            }
          });
        };
    
        const opciones = {
          threshold: 0.5 // Puedes ajustar el umbral según tus necesidades
        };
    
        const observer = new IntersectionObserver(handleIntersection, opciones);
    
        if (serviciosElement) {
          observer.observe(serviciosElement);
        }
    
        // Detener la observación cuando el componente se desmonta
        return () => {
          if (serviciosElement) {
            observer.unobserve(serviciosElement);
          }
        };
      }, []);

    const images = [
    'carousel1.png',
    'carousel2.png',
    'carousel3.png',
  ];

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column', // Ajusta a 'column' para apilar los elementos verticalmente
    alignItems: 'center',
    height: '92vh',
  };

  const titleStyle = {
    marginBottom: '20px', // Espaciado entre el título y el carrusel
    marginTop:'40px',
    fontSize: '24px', // Ajusta el tamaño del título según tus preferencias
    fontWeight: 'normal',
    color:'white',
  };

  const sliderContainerStyle = {
    maxWidth: '1000px',
    width: '100%',
    margin: '20px 0', // Agrega margen en la parte inferior del carrusel
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i, active) => (
      <div style={active ? activeDotStyle : dotStyle}></div> 
    ),  };
    
  const dotStyle = {
    position: 'relative',
    display: 'inline-block',
    width: '6px',
    height: '6px',
    margin: '0 5px',
    borderRadius: '50%',
    background: 'white', // Cambia el color de fondo a blanco
    cursor: 'pointer',
  };
  

  const activeDotStyle = {
    ...dotStyle,
    background: 'red', 
  };
  

  return (
    <section className='page5' id='nosotros' style={containerStyle} ref={serviciosRef}>
      <div style={titleStyle}>Nosotros</div>
      <div style={sliderContainerStyle}>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} style={{ borderRadius: '8px', overflow: 'hidden' }}>
              <img src={image} alt={`Slide ${index + 1}`} style={{ width: '100%' }} />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Page5;
