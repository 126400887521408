import React, { useState,useRef,useEffect} from 'react';
import './IndemnizacionCalculator.css';

const IndemnizacionCalculator = () => {
  const [ingreso, setIngreso] = useState('');
  const [egreso, setEgreso] = useState('');
  const [sueldo, setSueldo] = useState('');
  const [tipoLiquidacion, setTipoLiquidacion] = useState('');
  const [preaviso, setPreaviso] = useState('');
  const [vacaciones, setVacaciones] = useState('');
  const [resultado, setResultado] = useState(null);
  const componenteRef = useRef(null);

  const calcularIndemnizacion = () => {
    if (!ingreso || !egreso || !sueldo || !tipoLiquidacion || !preaviso || !vacaciones) {
      alert('Por favor, complete todos los campos.');
      return;
    }
  
    const fechaIngreso = new Date(ingreso);
    const fechaEgreso = new Date(egreso);
    const diasIndemnizacion = (fechaEgreso - fechaIngreso) / (1000 * 60 * 60 * 24);
  
    let añosTrabajados = diasIndemnizacion / 365;
    const mesesRestantes = añosTrabajados % 1;
  
    if (mesesRestantes >= 0.25 && mesesRestantes < 0.75) {
      // Si quedan entre 3 y 11 meses, contarlos como un año más
      añosTrabajados = Math.floor(añosTrabajados) + 1;
    } else if (mesesRestantes >= 0.75) {
      // Si quedan 12 o más meses, contarlos como dos años más
      añosTrabajados = Math.ceil(añosTrabajados);
    } else {
      añosTrabajados = Math.floor(añosTrabajados);
    }
  
    let indemnizacion = 0;
  
    if (tipoLiquidacion === 'DespidoSinCausa') {
      indemnizacion = parseFloat(sueldo) * (añosTrabajados);
      if (preaviso === 'NoPreaviso') {
        indemnizacion += parseFloat(sueldo);
      }
    } else if (tipoLiquidacion === 'Renuncia'|| tipoLiquidacion === 'DespidoConCausa' ) {
      // Calcular días trabajados en el último mes (suponiendo que el egreso es el último día del mes)
      const ultimoDiaMes = new Date(fechaEgreso.getFullYear(), fechaEgreso.getMonth() + 1, 0).getDate();
      const diasTrabajadosUltimoMes = ultimoDiaMes - fechaEgreso.getDate() + 1;
  
      // Calcular salario proporcional del mes en curso
      const salarioDiario = parseFloat(sueldo) / 30;
      const salarioProporcionalMes = salarioDiario * diasTrabajadosUltimoMes;
  
      indemnizacion = salarioProporcionalMes;
    }
    
    if (vacaciones === 'NoGozadas') {
      indemnizacion += (parseFloat(sueldo) / 30) * 14;
    }
    
    setResultado(indemnizacion.toFixed(2));
  };





  useEffect(() => {
    const componenteElement = componenteRef.current;

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // El elemento está en la vista
          componenteElement.classList.add('aparecer');
        } else {
          // El elemento ya no está en la vista
          componenteElement.classList.remove('aparecer');
        }
      });
    };

    const opciones = {
      threshold: 0.5 // Puedes ajustar el umbral según tus necesidades
    };

    const observer = new IntersectionObserver(handleIntersection, opciones);

    if (componenteElement) {
      observer.observe(componenteElement);
    }

    return () => {
      if (componenteElement) {
        observer.unobserve(componenteElement);
      }
    };
  }, []);

  return (
    <section className="contenedorcalculador" id='calculador' ref={componenteRef}>
    <div className="IndemnizacionCalculator">
      <h1>Calculador de Indemnización</h1>
      <div className="form">
        <label>Fecha de ingreso:
          <input type="date" value={ingreso} onChange={(e) => setIngreso(e.target.value)} />
        </label>
        <label>Fecha de egreso:
          <input type="date" value={egreso} onChange={(e) => setEgreso(e.target.value)} />
        </label>
        <label>Sueldo bruto en $:
          <input type="number" value={sueldo} onChange={(e) => setSueldo(e.target.value)} />
        </label>
        <label>
          Tipo de Liquidación:
          <select value={tipoLiquidacion} onChange={(e) => setTipoLiquidacion(e.target.value)}>
            <option value="">Seleccione...</option>
            <option value="DespidoSinCausa">Despido sin causa</option>
            <option value="DespidoConCausa">Despido con causa</option>
            <option value="Renuncia">Renuncia</option>
          </select>
        </label>
        <label>
          Preaviso:
          <select value={preaviso} onChange={(e) => setPreaviso(e.target.value)}>
            <option value="">Seleccione...</option>
            <option value="Preaviso">Preaviso</option>
            <option value="NoPreaviso">No preaviso</option>
            <option value="NoCorresponde">-</option>
          </select>
        </label>
        <label>
          Vacaciones:
          <select value={vacaciones} onChange={(e) => setVacaciones(e.target.value)}>
            <option value="">Seleccione...</option>
            <option value="Gozadas">Gozadas</option>
            <option value="NoGozadas">No gozadas</option>
          </select>
        </label>

        <button onClick={calcularIndemnizacion}>Calcular</button>
      </div>
      {resultado && (
        <div className="resultado">
          <h2>Resultado Aproximado</h2>
          <p>{resultado}</p>
        </div>
      )}
    </div>
    </section>
  );
};

export default IndemnizacionCalculator;
