import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Navbar from './navegador';
import Servicios from './Servicios';
import Clientes from './Clientes';
import Page5 from './Page5';
import IndemnizacionCalculator from './Page4';
import Contacto from './Contact';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
    <Navbar />
    <App />
    <Servicios/>
    <Clientes/> 
    <Page5/>
    <IndemnizacionCalculator/>
    <Contacto/>
    </React.StrictMode>
);

