import React, { useEffect, useRef } from 'react';
import './Clientes.css'; // Asegúrate de tener el archivo de estilos correspondiente

const Clientes = () => {
  const componenteRef = useRef(null);

  useEffect(() => {
    const componenteElement = componenteRef.current;

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // El elemento está en la vista
          componenteElement.classList.add('aparecer');
        } else {
          // El elemento ya no está en la vista
          componenteElement.classList.remove('aparecer');
        }
      });
    };

    const opciones = {
      threshold: 0.3 // Puedes ajustar el umbral según tus necesidades
    };

    const observer = new IntersectionObserver(handleIntersection, opciones);

    if (componenteElement) {
      observer.observe(componenteElement);
    }

    return () => {
      if (componenteElement) {
        observer.unobserve(componenteElement);
      }
    };
  }, []);

  return (
    <section className="componente" id='servicios' ref={componenteRef}>
      <h1 className="tituloComponente">Nuestros Servicios</h1>
      <div className="grid-container">
        <div className="grid-item">
          <img src="1.png" alt="" className="imagen" />
          <div className="tituloImagen">Despidos</div>
        </div>
        <div className="grid-item">
          <img src="2.png" alt="" className="imagen" />
          <div className="tituloImagen">Diferencias Salariales</div>
        </div>
        <div className="grid-item">
          <img src="3.png" alt="" className="imagen" />
          <div className="tituloImagen">Indemnización</div>
        </div>
        <div className="grid-item">
          <img src="4.png" alt="" className="imagen" />
          <div className="tituloImagen">Accidentes Laborales</div>
        </div>
        <div className="grid-item">
          <img src="6.png" alt="" className="imagen" />
          <div className="tituloImagen">Enfermedades Profesionales</div>
        </div>
        <div className="grid-item">
          <img src="5.png" alt="" className="imagen" />
          <div className="tituloImagen">Sucesiones</div>
        </div>
      </div>
    </section>
  );
};

export default Clientes;
