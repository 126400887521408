import React, { useEffect, useRef } from 'react';


function App() {
  const inicioRef = useRef(null);



  useEffect(() => {
    const inicioElement = inicioRef.current;

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // El elemento está en la vista
          inicioElement.classList.add('aparecer');
        } else {
          // El elemento ya no está en la vista
          inicioElement.classList.remove('aparecer');
        }
      });
    };

    const opciones = {
      threshold: 0.5 // Puedes ajustar el umbral según tus necesidades
    };

    const observer = new IntersectionObserver(handleIntersection, opciones);

    if (inicioElement) {
      observer.observe(inicioElement);
    }

    return () => {
      if (inicioElement) {
        observer.unobserve(inicioElement);
      }
    };
  }, []);

  return (

  <section>
<section className="inicio" id="inicio" ref={inicioRef}>
</section>
  </section>
);
}
export default App;
