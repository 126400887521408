import React, { useEffect, useRef } from 'react';
import './Servicios.css';

const Servicios = () => {
  const serviciosRef = useRef(null);

  useEffect(() => {
    const serviciosElement = serviciosRef.current;

    const handleIntersection = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // El elemento está en la vista
          serviciosElement.classList.add('aparecer');
        } else {
          // El elemento ya no está en la vista
          serviciosElement.classList.remove('aparecer');
        }
      });
    };

    const opciones = {
      threshold: 0.4 // Puedes ajustar el umbral según tus necesidades
    };

    const observer = new IntersectionObserver(handleIntersection, opciones);

    if (serviciosElement) {
      observer.observe(serviciosElement);
    }

    // Detener la observación cuando el componente se desmonta
    return () => {
      if (serviciosElement) {
        observer.unobserve(serviciosElement);
      }
    };
  }, []);

  return (
    <section className="servicios" id='misión' ref={serviciosRef}>
      <div className="servicios-container">
        <div className="textServicios">
          <h1>Nuestra misión</h1>
          <p className='text'>"La misión de nuestro estudio jurídico es proporcionar a nuestros clientes un servicio legal excepcional y representación experta en una variedad de asuntos legales. Nos comprometemos a salvaguardar sus derechos e intereses, ofreciendo atención personalizada y dedicación. Buscamos establecer relaciones sólidas y duraderas basadas en la confianza, la integridad y la excelencia. Nuestro objetivo es lograr los mejores resultados posibles para nuestros clientes, manteniendo los más altos estándares éticos y profesionales."</p>
        </div>
        <div className="imagenServicios">
          <img src="FONDOSERVICIOS.png" alt="" />
        </div>
      </div>
    </section>
  );
};

export default Servicios;
